import React from "react"
import clsx from "clsx"

import makeStyles from "@material-ui/core/styles/makeStyles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    display: "block",
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.common.white,
    fontSize: 30,
    lineHeight: "38px",

    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
      fontSize: 26,
      padding: theme.spacing(1, 3),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
      fontSize: 22,
      lineHeight: "26px",
      padding: theme.spacing(1, 2),
    },
  },
}))

const SectionTitle = ({ children, ...props }) => {
  const classes = useStyles()
  const { className } = props


  return (
    <Typography {...props} className={clsx(className, classes.root)}>
      {children}
    </Typography>
  )
}

export default SectionTitle
