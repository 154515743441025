import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"

import makeStyles from "@material-ui/core/styles/makeStyles"

import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Typography } from "@material-ui/core"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
    expanded: {
        "&$expanded": {
            margin: "0 auto",
            minHeight: 0,
        },
    },

    summaryExpanded: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
        minHeight: "0 !important",
    },
    expandIcon: {
        color: "#fff",
    },
    panel: {
        marginBottom: 5,
    },

    summaryContent: {
        margin: 0,
        alignItems: "center",
    },
    panelHeader: {
        background: theme.palette.aresBlue.main,
        padding: "0 10px",
        margin: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center right",

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2)
        },
    },

    panelDetails: {
        background: "#f0f0f0",
    },
    headingText: {
        color: "#fff",
        flex: 1,
        lineHeight: 1.1,
        fontSize: '1.2rem'
    },
}))

const Accordion = ({ items }) => {
    const classes = useStyles()

    return (
        <div>
            {items.map(item => (
                <MuiAccordion
                    square
                    key={shortid.generate()}
                    className={classes.panel}
                    classes={{ expanded: classes.expanded }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.panelHeader}
                        classes={{
                            content: classes.summaryContent,
                            expanded: classes.summaryExpanded,
                            expandIcon: classes.expandIcon,
                        }}
                    >
                        <Typography className={classes.headingText}>
                            {item.section_title.text}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelDetails}>
                        <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                                __html: item.section_content.html,
                            }} />
                    </AccordionDetails>
                </MuiAccordion>
            ))}
        </div>
    )
}

export default Accordion

Accordion.propTypes = {
    items: PropTypes.array.isRequired,
}
