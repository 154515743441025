import React, { useContext } from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { Link, graphql, useStaticQuery } from "gatsby"
import clsx from "clsx"

import LangContext from "../../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  menu: {
    overflow: "hidden",
    transition: "all 0.2s",
    listStylePosition: "outside",
    padding: theme.spacing(0),
    listStyleType: "none",

    "& a": {
      color: theme.palette.common.black,
    },

    "& span": {
      color: theme.palette.common.black,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
    "& a:hover": {
      color: theme.palette.aresBlue.main,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
  },

  menuItem: {
    marginBottom: 10,
  },

  menuItemActive: {
    "& a": {
      color: theme.palette.aresBlue.main,
      borderBottom: `1px solid ${theme.palette.aresBlue.main}`,
    },
  },
}))

const MenuItems = ({ locale, location }) => {
  const currentLink = `${location.pathname}${location.hash}`.replace(/^\//, "")
  const data = useStaticQuery(query)

  const departmentPage = data.departmentsPage.edges
    .filter(edge => edge.node.lang === locale)
    .map(r => r.node)
    .pop()

  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <Typography component="h2" variant="h2" style={{ marginTop: 0 }}>
        {i18n.translations.ourDepartments}
      </Typography>
      <ul className={classes.menu}>
        {departmentPage.data.departments.map(department => (
          <li
            key={department.department.document.data.link}
            className={clsx(
              classes.menuItem,
              currentLink === department.department.document.data.link ? classes.menuItemActive : ""
            )}
          >
            <Link to={`/${department.department.document.data.link}`}>
              {department.department.document.data.title.text}{" "}
            </Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export default MenuItems

const query = graphql`
  query DepartmentsMenuQuery {
    departmentsPage: allPrismicDepartments {
      edges {
        node {
          data {
            departments {
              department {
                document {
                  ... on PrismicDepartment {                                    
                    data {
                      link
                      display_image {
                        gatsbyImageData(layout: FIXED, width: 460, height: 65)
                      }
                      icon {
                        gatsbyImageData(layout: FIXED, width: 60, height: 65)
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            title
          }
          lang
        }
      }
    }
  }
`

MenuItems.propTypes = {
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}
