const genderType = {
    male: 'male',
    female: 'female'
}

const scaleType = {
    low: 'low',
    moderate: 'moderate',
    high: 'high',
    veryHigh: 'veryHigh'
}

const calculators = {
    score2: {
        type: 'score2', // https://www.mdcalc.com/calc/10499/systematic-coronary-risk-evaluation-score2#evidence
        coefficients: {
            [genderType.male]: {
                age: 0.3742,
                smoking: 0.6012,
                sbp: 0.2777,
                tchol: 0.1458,
                hdl: -0.2698,
                csmoking_x_cage: -0.0755,
                csbp_x_cage: -0.0255,
                ctchol_x_cage: -0.0281,
                chdl_x_cage: 0.0426,
                baselineSurvival: 0.9605,
                scales: {
                    veryHigh: {
                        scale1: 0.5836,
                        scale2: 0.8294
                    }
                }
            },
            [genderType.female]: {
                age: 0.4648,
                smoking: 0.7744,
                sbp: 0.3131,
                tchol: 0.1002,
                hdl: -0.2606,
                csmoking_x_cage: -0.1088,
                csbp_x_cage: -0.0277,
                ctchol_x_cage: -0.0226,
                chdl_x_cage: 0.0613,
                baselineSurvival: 0.9776,
                scales: {
                    veryHigh: {
                        scale1: 0.9412,
                        scale2: 0.8329
                    }
                }
            }
        },
        calc: (
            age,  // number 40-69
            gender, // 'male' or 'female'
            smoking, // boolean 1/0
            systolicBloodPressure, // number mm Hg
            totalCholesterol, // number mmol/L
            hdlCholesterol, // number mmol/L
            scaleType = 'veryHigh'
        ) => {
            if (!(
                (age >= 40 && age <= 69) &&
                (smoking === true || smoking === false) &&
                Object.values(genderType).includes(gender) &&
                systolicBloodPressure &&
                totalCholesterol &&
                hdlCholesterol
            )) {
                return null
            }

            console.log('[score2 calculator] Calculate CVD risk using Score2 and using', {
                age,
                gender,
                smoking,
                systolicBloodPressure,
                totalCholesterol,
                hdlCholesterol
            })

            const cage = (age - 60) / 5
            const csmoking = smoking ? 1 : 0
            const csbp = (systolicBloodPressure - 120) / 20
            const ctchol = totalCholesterol - 6
            const chdl = (hdlCholesterol - 1.3) / 0.5
            const csmoking_x_cage = csmoking * cage
            const csbp_x_cage = csbp * cage
            const ctchol_x_cage = ctchol * cage
            const chdl_x_cage = chdl * cage

            console.log(`[score2 calculator] Transformed variables:`, {
                cage,
                csmoking,
                csbp,
                ctchol,
                chdl,
                csmoking_x_cage,
                csbp_x_cage,
                ctchol_x_cage,
                chdl_x_cage
            })

            const coefficients = calculators.score2.coefficients[gender]

            console.log(`[score2 calculator] Using the following coefficients for ${gender}`, coefficients)

            //  FORMULA: x = Σ[β*(transformed variables)]
            let x = 0;

            x += coefficients.age * cage;
            x += coefficients.smoking * csmoking;
            x += coefficients.sbp * csbp;
            x += coefficients.tchol * ctchol;
            x += coefficients.hdl * chdl;
            x += coefficients.csmoking_x_cage * csmoking_x_cage;
            x += coefficients.csbp_x_cage * csbp_x_cage;
            x += coefficients.ctchol_x_cage * ctchol_x_cage;
            x += coefficients.chdl_x_cage * chdl_x_cage;


            // FORMULA: 10-year risk = 1 – (baseline survival)exp(x)
            const risk = 1 - Math.pow(coefficients.baselineSurvival, Math.exp(x));

            console.log(`[score2 calculator] 10-year risk = ${risk}`)

            const scale1 = coefficients.scales[scaleType].scale1
            const scale2 = coefficients.scales[scaleType].scale2

            // FORMULA: Calibrated 10-year risk, % = [1 – exp(-exp(scale1 + scale2*ln(-ln(1 – 10-year risk))))]*100
            const calibratedRisk = (1 - Math.exp(-Math.exp(scale1 + scale2 * Math.log(-Math.log(1 - risk))))) * 100;

            console.log(`[score2 calculator] Calibrated 10-year risk = ${calibratedRisk} %`)

            return calibratedRisk
        }
    },
    score2op: {
        type: 'score2op', // https://www.mdcalc.com/calc/10503/score2-older-persons-score2-op#evidence
        coefficients: {
            [genderType.male]: {
                age: 0.0634,
                diabetes: 0.4245,
                smoking: 0.3524,
                sbp: 0.0094,
                tchol: 0.0850,
                hdl: -0.3564,
                cdiabetes_x_cage: -0.0174,
                csmoking_x_cage: -0.0247,
                csbp_x_cage: -0.0005,
                ctchol_x_cage: 0.0073,
                chdl_x_cage: 0.0091,
                baselineSurvival: 0.7576,
                meanLinearPredictor: 0.0929,
                scales: {
                    veryHigh: {
                        scale1: 0.05,
                        scale2: 0.70
                    }
                }
            },
            [genderType.female]: {
                age: 0.0789,
                diabetes: 0.6010,
                smoking: 0.4921,
                sbp: 0.0102,
                tchol: 0.0605,
                hdl: -0.3040,
                cdiabetes_x_cage: -0.0107,
                csmoking_x_cage: -0.0255,
                csbp_x_cage: -0.0004,
                ctchol_x_cage: -0.0009,
                chdl_x_cage: 0.0154,
                baselineSurvival: 0.8082,
                meanLinearPredictor: 0.2290,
                scales: {
                    veryHigh: {
                        scale1: 0.38,
                        scale2: 0.69
                    }
                }
            }
        },
        calc: (
            age,  // number >= 70
            gender, // 'male' or 'female'
            diabetes, // boolean 1/0
            smoking, // boolean 1/0
            systolicBloodPressure, // number mm Hg
            totalCholesterol, // number mmol/L
            hdlCholesterol, // number mmol/L
            scaleType = 'veryHigh'
        ) => {
            if (!(
                (age >= 70) &&
                (smoking === true || smoking === false) &&
                (diabetes === true || diabetes === false) &&
                Object.values(genderType).includes(gender) &&
                systolicBloodPressure &&
                totalCholesterol &&
                hdlCholesterol
            )) {
                return null
            }

            console.log('[score2op calculator] Calculate CVD risk using Score2 (older people) and using', {
                age,
                gender,
                diabetes,
                smoking,
                systolicBloodPressure,
                totalCholesterol,
                hdlCholesterol
            })

            const cage = age - 73
            const cdiabetes = diabetes ? 1 : 0
            const csmoking = smoking ? 1 : 0
            const csbp = systolicBloodPressure - 150
            const ctchol = totalCholesterol - 6
            const chdl = hdlCholesterol - 1.4
            const cdiabetes_x_cage = cdiabetes * cage
            const csmoking_x_cage = csmoking * cage
            const csbp_x_cage = csbp * cage
            const ctchol_x_cage = ctchol * cage
            const chdl_x_cage = chdl * cage

            console.log(`[score2op calculator] Transformed variables:`, {
                cage,
                cdiabetes,
                csmoking,
                csbp,
                ctchol,
                chdl,
                cdiabetes_x_cage,
                csmoking_x_cage,
                csbp_x_cage,
                ctchol_x_cage,
                chdl_x_cage
            })

            const coefficients = calculators.score2op.coefficients[gender]

            console.log(`[score2op calculator] Using the following coefficients for ${gender}`, coefficients)

            //  FORMULA: x = Σ[β*(transformed variables)]
            let x = 0;

            x += coefficients.age * cage;
            x += coefficients.diabetes * cdiabetes;
            x += coefficients.smoking * csmoking;
            x += coefficients.sbp * csbp;
            x += coefficients.tchol * ctchol;
            x += coefficients.hdl * chdl;
            x += coefficients.cdiabetes_x_cage * cdiabetes_x_cage;
            x += coefficients.csmoking_x_cage * csmoking_x_cage;
            x += coefficients.csbp_x_cage * csbp_x_cage;
            x += coefficients.ctchol_x_cage * ctchol_x_cage;
            x += coefficients.chdl_x_cage * chdl_x_cage;


            // FORMULA: 10-year risk = 1 – (baseline survival)exp(x)
            const risk = 1 - Math.pow(coefficients.baselineSurvival, Math.exp(x - coefficients.meanLinearPredictor));

            console.log(`[score2op calculator] 10-year risk = ${risk}`)

            const scale1 = coefficients.scales[scaleType].scale1
            const scale2 = coefficients.scales[scaleType].scale2

            // FORMULA: Calibrated 10-year risk, % = [1 – exp(-exp(scale1 + scale2*ln(-ln(1 – 10-year risk))))]*100
            const calibratedRisk = (1 - Math.exp(-Math.exp(scale1 + scale2 * Math.log(-Math.log(1 - risk))))) * 100;

            console.log(`[score2op calculator] Calibrated 10-year risk = ${calibratedRisk} %`)

            return calibratedRisk
        }
    },
    score2diabetes: {
        type: 'score2diabetes', // https://www.mdcalc.com/calc/10510/score2-diabetes#evidence
        coefficients: {
            [genderType.male]: {
                age: 0.5368,
                smoking: 0.4774,
                sbp: 0.1322,
                diabetes: 0.6457,
                tchol: 0.1102,
                hdl: -0.1087,
                csmoking_x_cage: -0.0672,
                csbp_x_cage: -0.0268,
                cdiabetes_x_cage: -0.0983,
                ctchol_x_cage: -0.0181,
                chdl_x_cage: 0.0095,
                cagediab: -0.0998,
                ca1c: 0.0955,
                cegfr: -0.0591,
                cegfr2: 0.0058,
                ca1c_x_cage: -0.0134,
                cegfr_x_cage: 0.0115,
                baselineSurvival: 0.9605,
                scales: {
                    veryHigh: {
                        scale1: 0.5836,
                        scale2: 0.8294
                    }
                }
            },
            [genderType.female]: {
                age: 0.6624,
                smoking: 0.6139,
                sbp: 0.1421,
                diabetes: 0.8096,
                tchol: 0.1127,
                hdl: -0.1568,
                csmoking_x_cage: -0.1122,
                csbp_x_cage: -0.0167,
                cdiabetes_x_cage: -0.1272,
                ctchol_x_cage: -0.0200,
                chdl_x_cage: 0.0186,
                cagediab: -0.1180,
                ca1c: 0.1173,
                cegfr: -0.0640,
                cegfr2: 0.0062,
                ca1c_x_cage: -0.0196,
                cegfr_x_cage: 0.0169,
                baselineSurvival: 0.9776,
                scales: {
                    veryHigh: {
                        scale1: 0.9412,
                        scale2: 0.8329
                    }
                }
            }
        },
        calc: (
            age,  // number 40-69
            gender, // 'male' or 'female'
            diabetes, // boolean 1/0
            smoking, // boolean 1/0
            systolicBloodPressure, // number mm Hg
            totalCholesterol, // number mmol/L
            hdlCholesterol, // number mmol/L
            ageAtDiabetesDiagnosis, // number <= 69
            hba1c, // number      
            egfr, // number mL/min/1.73 m²
            scaleType = 'veryHigh'
        ) => {
            if (!(
                (age >= 40 && age <= 69) &&
                (diabetes === true || diabetes === false) &&
                (smoking === true || smoking === false) &&
                Object.values(genderType).includes(gender) &&
                systolicBloodPressure &&
                totalCholesterol &&
                hdlCholesterol &&
                ageAtDiabetesDiagnosis &&
                hba1c &&
                egfr
            )) {
                return null
            }

            console.log('[score2diabetes calculator] Calculate CVD risk using Score2 (with diabetes) and using', {
                age,
                gender,
                diabetes,
                smoking,
                systolicBloodPressure,
                totalCholesterol,
                hdlCholesterol,
                ageAtDiabetesDiagnosis,
                hba1c,
                egfr
            })

            const cage = (age - 60) / 5
            const csmoking = smoking ? 1 : 0
            const csbp = (systolicBloodPressure - 120) / 20
            const cdiabetes = diabetes ? 1 : 0
            const ctchol = totalCholesterol - 6
            const chdl = (hdlCholesterol - 1.3) / 0.5
            const csmoking_x_cage = csmoking * cage
            const csbp_x_cage = csbp * cage
            const cdiabetes_x_cage = cdiabetes * cage
            const ctchol_x_cage = ctchol * cage
            const chdl_x_cage = chdl * cage
            const cagediab = diabetes * (ageAtDiabetesDiagnosis - 50) / 5
            const ca1c = (hba1c - 31) / 9.34
            const cegfr = (Math.log(egfr) - 4.5) / 0.15
            const cegfr2 = Math.pow(cegfr, 2)
            const ca1c_x_cage = ca1c * cage
            const cegfr_x_cage = cegfr * cage

            console.log(`[score2diabetes calculator] Transformed variables:`, {
                cage,
                csmoking,
                csbp,
                cdiabetes,
                ctchol,
                chdl,
                csmoking_x_cage,
                csbp_x_cage,
                cdiabetes_x_cage,
                ctchol_x_cage,
                chdl_x_cage,
                cagediab,
                ca1c,
                cegfr,
                cegfr2,
                ca1c_x_cage,
                cegfr_x_cage
            })

            const coefficients = calculators.score2diabetes.coefficients[gender]

            console.log(`[score2diabetes calculator] Using the following coefficients for ${gender}`, coefficients)

            //  FORMULA: x = Σ[β*(transformed variables)]
            let x = 0;

            x += coefficients.age * cage;
            x += coefficients.smoking * csmoking;
            x += coefficients.sbp * csbp;
            x += coefficients.diabetes * cdiabetes;
            x += coefficients.tchol * ctchol;
            x += coefficients.hdl * chdl;
            x += coefficients.csmoking_x_cage * csmoking_x_cage;
            x += coefficients.csbp_x_cage * csbp_x_cage;
            x += coefficients.cdiabetes_x_cage * cdiabetes_x_cage;
            x += coefficients.ctchol_x_cage * ctchol_x_cage;
            x += coefficients.chdl_x_cage * chdl_x_cage;
            x += coefficients.cagediab * cagediab;
            x += coefficients.ca1c * ca1c;
            x += coefficients.cegfr * cegfr;
            x += coefficients.cegfr2 * cegfr2;
            x += coefficients.ca1c_x_cage * ca1c_x_cage;
            x += coefficients.cegfr_x_cage * cegfr_x_cage;


            // FORMULA: 10-year risk = 1 – (baseline survival)exp(x)
            const risk = 1 - Math.pow(coefficients.baselineSurvival, Math.exp(x));

            console.log(`[score2diabetes calculator] 10-year risk = ${risk}`)

            const scale1 = coefficients.scales[scaleType].scale1
            const scale2 = coefficients.scales[scaleType].scale2

            // FORMULA: Calibrated 10-year risk, % = [1 – exp(-exp(scale1 + scale2*ln(-ln(1 – 10-year risk))))]*100
            const calibratedRisk = (1 - Math.exp(-Math.exp(scale1 + scale2 * Math.log(-Math.log(1 - risk))))) * 100;

            console.log(`[score2diabetes calculator] Calibrated 10-year risk = ${calibratedRisk} %`)

            return calibratedRisk
        }
    },
    egfr: {
        type: 'egfr', // https://www.mdcalc.com/calc/76/mdrd-gfr-equation#evidence
        coefficients: {
            male: {
                constant: 175,
                age: -0.203,
                gender: 1,
                creatinine: -1.154,
            },
            female: {
                constant: 175,
                age: -0.203,
                gender: 0.742,
                creatinine: -1.154,
            }
        },
        calc: (
            age, // number
            gender, // 'male' or 'female'
            creatinine // number in mgd/L
        ) => {
            const coefficients = calculators.egfr.coefficients[gender]

            const serumCrFactor = Math.pow(creatinine, coefficients.creatinine);
            const ageFactor = Math.pow(age, coefficients.age);
            const raceFactor = 1 // we are assuming white pacients by default;
            const genderFactor = coefficients.gender;

            const gfr = coefficients.constant * serumCrFactor * ageFactor * raceFactor * genderFactor;

            const egfr = Math.round(gfr * 10) / 10

            console.log(`[egfr calculator] egfr = ${egfr}`)

            return egfr
        }
    },
    mgdl2µmoll: {
        calc: (mgdl) => {
            return Math.round((mgdl * 88.40) * 100) / 100
        }
    },
    µmoll2mgdl: {
        calc: (µmoll) => {
            return Math.round((µmoll / 88.40) * 100) / 100
        }
    },
    mgdl2mmol: {
        calc: (mgdl) => {
            return Math.round((mgdl / 38.67) * 100) / 100
        }
    },
    mmol2mgdl: {
        calc: (mmol) => {
            return Math.round((mmol * 38.67) * 100) / 100
        }
    },
    a1cPercentageToMmolMol: {
        coefficients: {
            slope: 10.929,
            intercept: 2.15
        },
        calc: (a1cPercentage) => {
            const { slope, intercept } = calculators.a1cPercentageToMmolMol.coefficients

            const result = (a1cPercentage - intercept) * slope;

            return Math.round(result * 100) / 100
        }
    },
    a1cMmolMolToPercentage: {
        coefficients: {
            slope: 10.929,
            intercept: 2.15
        },
        calc: (a1cMmolMol) => {
            const { slope, intercept } = calculators.a1cMmolMolToPercentage.coefficients

            const result = (a1cMmolMol / slope) + intercept;

            return Math.round(result * 100) / 100
        }
    }
}

export { calculators }

