import React, { useContext, useEffect, useRef, useState } from "react"

import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"

import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"
import LangContext from "../../contexts/LangContext"
import CustomTextField from "./CustomTextField"
import CustomButton from "../buttons/CustomButton"

import iconLowRisk from "../../img/icon_low_risk.png"
import iconModerateRisk from "../../img/icon_moderate_risk.png"
import iconHighRisk from "../../img/icon_high_risk.png"

import { Button, Card, CardContent, Divider, FormControlLabel, FormHelperText, FormLabel, Paper, Radio, RadioGroup, TextField } from "@material-ui/core"
import { calculators } from "../../services/medical-calculator"

import "../../styles/riskCalculator.scss"
import { Link } from "gatsby"
import ContactPopup from "../contacts/ContactPopup"

const useStyles = makeStyles(theme => ({
  root: {

  },
  resultCardWrapper: {
    color: theme.palette.common.white,
  },
  resultCardButton: {
    borderRadius: 50,
    width: 'auto'
  },

  riskTitle: {
    fontSize: 40,
    fontWeight: 700,
    textWrap: 'nowrap',

    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },

  },

  riskIconImg: {
    maxWidth: 100,
    height: 70,

    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
  },

  riskCardBottomText: {
    fontSize: 24,
    width: '60%',
    display: 'inline-block',
    '& b': {
      fontWeight: 700,
    },

    [theme.breakpoints.down("sm")]: {
      width: '80%'
    },
  },


  button: {
    padding: 0,
    opacity: 0.3,
    textAlign: 'left',

    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    }
  },
  selected: {
    color: theme.palette.secondary.main,
    textTransform: 'none',
    pointerEvents: 'none',
    opacity: 1,

  }
}))

const valueType = {
  mgdl: 1,
  mmol: 2,
  µmoll: 3,
  percentage: 4,
  mmol_mol: 5
}

const riskCategoryType = {
  empty: 'empty',
  low: 'low',
  moderate: 'moderate',
  high: 'high',
}



const RiskCalculator = ({ location, locale }) => {
  const classes = useStyles()

  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [scoreCalculatorType, setScoreCalculatorType] = useState(calculators.score2.type)

  const [age, setAge] = useState(null)
  const [ageError, setAgeError] = useState(false)

  const [gender, setGender] = useState(null)
  const [diabetes, setDiabetes] = useState(null)
  const [smoking, setSmoking] = useState(null)

  const [totalCholesterol, setTotalCholesterol] = useState('')
  const [totalCholesterolValueType, setTotalCholesterolValueType] = useState(valueType.mgdl) // 1 - 'mg/dL', 2 - 'mmol/L'
  const [totalCholesterolMmol, setTotalCholesterolMmol] = useState(null)

  const [hdlCholesterol, setHdlCholesterol] = useState('')
  const [hdlCholesterolValueType, setHdlCholesterolValueType] = useState(valueType.mgdl) // 1 - 'mg/dL', 2 - 'mmol/L'
  const [hdlCholesterolMmol, setHdlCholesterolMmol] = useState(null)

  const [systolicBloodPressure, setSystolicBloodPressure] = useState(null)

  const [ageAtDiabetesDiagnosis, setAgeAtDiabetesDiagnosis] = useState(null)
  const [hba1c, setHba1c] = useState(null)
  const [hba1cValueType, setHba1cValueType] = useState(valueType.percentage) // 4 - 'percentage', 5 - 'mmol/mol'
  const [hba1cMmolMol, setHba1cMmolMol] = useState(null)

  const [creatinine, setCreatinine] = useState('')
  const [creatinineValueType, setCreatinineValueType] = useState(valueType.mgdl) // 1 - 'mg/dL', 3 - 'µmol/L'
  const [creatinineMgdl, setCreatinineMgdl] = useState(null)

  const [egfr, setEgfr] = useState(null)

  const [calculatedRisk, setCalculatedRisk] = useState(null)
  const [riskCategory, setRiskCategory] = useState(riskCategoryType.empty)
  const [riskCategoryIcon, setRiskCategoryIcon] = useState(null)

  const onChangeAge = (e) => {
    setAge(Number(e.target.value))
    setAgeError(e.target.value < 40 || e.target.value > 150)
  }

  const onChangeTotalCholesterol = (e) => {
    setTotalCholesterol(e.target.value ? Number(e.target.value) : '')
  }

  const onChangeHdlCholesterol = (e) => {
    setHdlCholesterol(e.target.value ? Number(e.target.value) : '')
  }

  const onChangeCreatinine = (e) => {
    setCreatinine(e.target.value ? Number(e.target.value) : '')
  }

  const onChangeHba1c = (e) => {
    setHba1c(e.target.value ? Number(e.target.value) : '')
  }

  // Calculate total cholesterol in mmoL
  useEffect(() => {
    if (totalCholesterolValueType === valueType.mmol) {
      setTotalCholesterolMmol(totalCholesterol)
    } else if (totalCholesterolValueType === valueType.mgdl) {
      setTotalCholesterolMmol(calculators.mgdl2mmol.calc(totalCholesterol))
    }
  }, [totalCholesterol, totalCholesterolValueType])

  // Calculate HDL cholesterol in mmoL
  useEffect(() => {
    if (hdlCholesterolValueType === valueType.mmol) {
      setHdlCholesterolMmol(hdlCholesterol)
    } else if (hdlCholesterolValueType === valueType.mgdl) {
      setHdlCholesterolMmol(calculators.mgdl2mmol.calc(hdlCholesterol))
    }
  }, [hdlCholesterol, hdlCholesterolValueType])

  // Convert total cholesterol from mmol to mgdl or vice versa whenever the value type changes
  useEffect(() => {
    if (!totalCholesterol) return

    if (totalCholesterolValueType === valueType.mmol) {
      // convert from mgdl to mmol
      setTotalCholesterol(calculators.mgdl2mmol.calc(totalCholesterol))
    } else if (totalCholesterolValueType === valueType.mgdl) {
      // convert from mmol to mgdl
      setTotalCholesterol(calculators.mmol2mgdl.calc(totalCholesterol))
    }
  }, [totalCholesterolValueType])


  // Convert HDL cholesterol from mmol to mgdl or vice versa whenever the value type changes
  useEffect(() => {
    if (!hdlCholesterol) return

    if (hdlCholesterolValueType === valueType.mmol) {
      // convert from mgdl to mmol
      setHdlCholesterol(calculators.mgdl2mmol.calc(hdlCholesterol))
    } else if (hdlCholesterolValueType === valueType.mgdl) {
      // convert from mmol to mgdl
      setHdlCholesterol(calculators.mmol2mgdl.calc(hdlCholesterol))
    }
  }, [hdlCholesterolValueType])

  // Calculate creatinine in mg/dL
  useEffect(() => {
    if (creatinineValueType === valueType.mgdl) {
      setCreatinineMgdl(creatinine)
    } else if (creatinineValueType === valueType.µmoll) {
      setCreatinineMgdl(calculators.µmoll2mgdl.calc(creatinine))
    }
  }, [creatinine, creatinineValueType])

  // Convert creatinine from mg/dL to µmoll or vice versa whenever the value type changes
  useEffect(() => {
    if (!creatinine) return

    if (creatinineValueType === valueType.µmoll) {
      // convert from mgdl to µmoll
      setCreatinine(calculators.mgdl2µmoll.calc(creatinine))
    } else if (creatinineValueType === valueType.mgdl) {
      // convert from µmoll to mgdl
      setCreatinine(calculators.µmoll2mgdl.calc(creatinine))
    }

  }, [creatinineValueType])

  // Convert HBA1C from percentage to mmol/mol or vice versa whenever the value type changes
  useEffect(() => {
    if (!hba1c) return

    if (hba1cValueType === valueType.percentage) {
      // convert mmol/mol to percentage
      setHba1c(calculators.a1cPercentageToMmolMol.calc(hba1c))
    } else if (hba1cValueType === valueType.mmol_mol) {
      // convert percentage to mmol/mol
      setHba1c(calculators.a1cMmolMolToPercentage.calc(hba1c))
    }
  }, [hba1cValueType])

  useEffect(() => {
    if (hba1cValueType === valueType.mmol_mol) {
      setHba1cMmolMol(hba1c)
    } else if (hba1cValueType === valueType.percentage) {
      setHba1cMmolMol(calculators.a1cPercentageToMmolMol.calc(hba1c))
    }
  }, [hba1c, hba1cValueType])


  useEffect(() => {
    if (age && gender && creatinineMgdl) {
      setEgfr(calculators.egfr.calc(age, gender, creatinineMgdl))
    }
  }, [age, gender, creatinineMgdl])

  // Calculate the score calculator type based on the age and diabetes
  useEffect(() => {
    if ((diabetes && age >= 40 && age <= 69) || (diabetes && !age)) {
      // Pacient is 40-69 years old and has diabetes
      setScoreCalculatorType(calculators.score2diabetes.type)
    } else if (age >= 70) {
      // Pacient is 70+ years old
      setScoreCalculatorType(calculators.score2op.type)
    } else {
      // Pacient is 40-69 years old
      setScoreCalculatorType(calculators.score2.type)
    }
  }, [age, diabetes])


  // Perform SCORE2 risk calculation
  useEffect(() => {
    if (scoreCalculatorType === calculators.score2.type) {
      const risk = calculators.score2.calc(
        age,
        gender,
        smoking,
        systolicBloodPressure,
        totalCholesterolMmol,
        hdlCholesterolMmol
      )
      setCalculatedRisk(risk)
    } else if (scoreCalculatorType === calculators.score2op.type) {
      const risk = calculators.score2op.calc(
        age,
        gender,
        diabetes,
        smoking,
        systolicBloodPressure,
        totalCholesterolMmol,
        hdlCholesterolMmol
      )
      setCalculatedRisk(risk)
    } else if (scoreCalculatorType === calculators.score2diabetes.type) {

      const risk = calculators.score2diabetes.calc(
        age,
        gender,
        diabetes,
        smoking,
        systolicBloodPressure,
        totalCholesterolMmol,
        hdlCholesterolMmol,
        ageAtDiabetesDiagnosis,
        hba1cMmolMol,
        egfr
      )
      setCalculatedRisk(risk)
    }
  }, [
    age,
    gender,
    diabetes,
    smoking,
    diabetes,
    totalCholesterolMmol,
    hdlCholesterolMmol,
    systolicBloodPressure,
    ageAtDiabetesDiagnosis,
    hba1cMmolMol,
    egfr
  ])

  // Calculate the risk category based on the age and calculated risk
  useEffect(() => {
    if (!calculatedRisk) return

    if (age < 50) {
      if (calculatedRisk < 2.5) {
        setRiskCategory(riskCategoryType.low)
      } else if (calculatedRisk < 7.5) {
        setRiskCategory(riskCategoryType.moderate)
      } else {
        setRiskCategory(riskCategoryType.high)
      }
    } else if (age >= 50 && age < 70) {
      if (calculatedRisk < 5) {
        setRiskCategory(riskCategoryType.low)
      } else if (calculatedRisk < 10) {
        setRiskCategory(riskCategoryType.moderate)
      } else {
        setRiskCategory(riskCategoryType.high)
      }
    } else if (age >= 70) {
      if (calculatedRisk < 7.5) {
        setRiskCategory(riskCategoryType.low)
      } else if (calculatedRisk < 15) {
        setRiskCategory(riskCategoryType.moderate)
      } else {
        setRiskCategory(riskCategoryType.high)
      }
    }
  }, [calculatedRisk, age])

  useEffect(() => {
    if (riskCategory === riskCategoryType.low) {
      setRiskCategoryIcon(iconLowRisk)
    } else if (riskCategory === riskCategoryType.moderate) {
      setRiskCategoryIcon(iconModerateRisk)
    } else if (riskCategory === riskCategoryType.high) {
      setRiskCategoryIcon(iconHighRisk)
    }
  }, [riskCategory])

  return (
    <div className={clsx(classes.root)}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">
            {i18n.translations.riskCalculator.title}
          </Typography>
          <form
            method="POST"
          >
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel htmlFor="ageInput">{i18n.translations.riskCalculator.age}</FormLabel>
                  </Grid>
                  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <CustomTextField
                      error={ageError}
                      size="small"
                      type="number"
                      id="ageInput"
                      name="age"
                      required
                      margin="none"
                      onChange={onChangeAge}
                      inputProps={{
                        min: 40,
                        max: 150
                      }}
                    />
                    {ageError && <FormHelperText error id="my-helper-text"><small>{i18n.translations.riskCalculator.error.age}</small></FormHelperText>}
                  </Grid>
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>


              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel htmlFor="gender">{i18n.translations.riskCalculator.gender}</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup aria-label="gender" name="gender" row onChange={(e) => setGender(e.target.value)}>
                      <FormControlLabel value="female" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.female} />
                      <FormControlLabel value="male" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.male} />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{i18n.translations.riskCalculator.diabetes}</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup aria-label="diabetes" name="diabetes" row onChange={(e) => setDiabetes(e.target.value === "1")}>
                      <FormControlLabel value="1" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.yes} />
                      <FormControlLabel value="0" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.no} />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel component="legend">{i18n.translations.riskCalculator.smoking}</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup aria-label="smoking" name="smoking" row onChange={(e) => setSmoking(e.target.value === "1")}>
                      <FormControlLabel value="1" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.yes} />
                      <FormControlLabel value="0" style={{ width: 120 }} control={<Radio />} label={i18n.translations.riskCalculator.no} />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel htmlFor="totalCholesterol">{i18n.translations.riskCalculator.totalCholesterol}</FormLabel>
                  </Grid>
                  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <CustomTextField
                      size="small"
                      type="number"
                      id="totalCholesterol"
                      name="totalCholesterol"
                      required
                      margin="none"
                      placeholder={i18n.translations.riskCalculator.placeholder.totalCholesterol[totalCholesterolValueType]}
                      onChange={onChangeTotalCholesterol}
                      value={totalCholesterol}
                    />
                    <div style={{ display: 'flex' }}>
                      <Button disableRipple margin="none" size="small" className={clsx(classes.button, totalCholesterolValueType === valueType.mgdl && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setTotalCholesterolValueType(valueType.mgdl)}>mg/dL</Button>
                      <Button disableRipple component={Button} size="small" className={clsx(classes.button, totalCholesterolValueType === valueType.mmol && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setTotalCholesterolValueType(valueType.mmol)}>mmol/L</Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel htmlFor="hdlCholesterol">{i18n.translations.riskCalculator.hdlCholesterol}</FormLabel>
                  </Grid>

                  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <CustomTextField
                      size="small"
                      type="number"
                      id="hdlCholesterol"
                      name="hdlCholesterol"
                      required
                      margin="none"
                      placeholder={i18n.translations.riskCalculator.placeholder.hdlCholesterol[hdlCholesterolValueType]}
                      onChange={onChangeHdlCholesterol}
                      value={hdlCholesterol}
                    />
                    <div style={{ display: 'flex' }}>
                      <Button disableRipple margin="none" size="small" className={clsx(classes.button, hdlCholesterolValueType === valueType.mgdl && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setHdlCholesterolValueType(valueType.mgdl)}>mg/dL</Button>
                      <Button disableRipple component={Button} size="small" className={clsx(classes.button, hdlCholesterolValueType === valueType.mmol && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setHdlCholesterolValueType(valueType.mmol)}>mmol/L</Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={3}>
                    <FormLabel htmlFor="systolicBloodPressure">{i18n.translations.riskCalculator.systolicBloodPressure}</FormLabel>
                  </Grid>
                  <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>

                    <CustomTextField
                      size="small"
                      type="number"
                      id="systolicBloodPressure"
                      name="systolicBloodPressure"
                      required
                      margin="none"
                      placeholder={i18n.translations.riskCalculator.placeholder.systolicBloodPressure}
                      onChange={(e) => setSystolicBloodPressure(Number(e.target.value))}
                    />
                    <Button disableRipple margin="none" size="small" className={clsx(classes.button, classes.selected)} style={{ textTransform: 'none' }} >mm Hg</Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{
                display: scoreCalculatorType === calculators.score2diabetes.type ? 'block' : 'none'
              }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={3}>
                        <FormLabel htmlFor="ageAtDiabetesDiagnosis">{i18n.translations.riskCalculator.ageAtDiabetesDiagnosis}</FormLabel>
                      </Grid>
                      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>

                        <CustomTextField
                          size="small"
                          type="number"
                          id="ageAtDiabetesDiagnosis"
                          name="ageAtDiabetesDiagnosis"
                          margin="none"
                          onChange={(e) => setAgeAtDiabetesDiagnosis(Number(e.target.value))}
                        />
                        <Button disableRipple margin="none" size="small" className={clsx(classes.button, classes.selected)} style={{ textTransform: 'none' }} >{i18n.translations.riskCalculator.ani}</Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={3}>
                        <FormLabel htmlFor="hba1c">{i18n.translations.riskCalculator.hba1c}</FormLabel>
                      </Grid>
                      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>

                        <CustomTextField
                          size="small"
                          type="number"
                          id="hba1c"
                          name="hba1c"
                          margin="none"
                          placeholder={i18n.translations.riskCalculator.placeholder.hba1c[hba1cValueType]}
                          value={hba1c}
                          onChange={onChangeHba1c}
                        />
                        <div style={{ display: 'flex' }}>
                          <Button disableRipple margin="none" size="small" className={clsx(classes.button, hba1cValueType === valueType.percentage && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setHba1cValueType(valueType.percentage)}>%</Button>
                          <Button disableRipple margin="none" size="small" className={clsx(classes.button, hba1cValueType === valueType.mmol_mol && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setHba1cValueType(valueType.mmol_mol)}>mmol/mol</Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={3}>
                        <FormLabel htmlFor="creatinine">{i18n.translations.riskCalculator.creatinine}</FormLabel>
                      </Grid>
                      <Grid item xs={9} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>

                        <CustomTextField
                          size="small"
                          type="number"
                          id="creatinine"
                          name="creatinine"
                          margin="none"
                          placeholder={i18n.translations.riskCalculator.placeholder.creatinine[creatinineValueType]}
                          onChange={onChangeCreatinine}
                          value={creatinine}
                        />
                        <div style={{ display: 'flex' }}>
                          <Button disableRipple margin="none" size="small" className={clsx(classes.button, creatinineValueType === valueType.mgdl && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setCreatinineValueType(valueType.mgdl)}>mg/dL</Button>
                          <Button disableRipple component={Button} size="small" className={clsx(classes.button, creatinineValueType === valueType.µmoll && classes.selected)} style={{ textTransform: 'none' }} onClick={() => setCreatinineValueType(valueType.µmoll)}>µmol/L</Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  className={clsx(classes.resultCardWrapper, `risk-category-${riskCategory}`)}
                >
                  <Grid item xs={12} className={'result-card-top'}>
                    <Grid container spacing={2} style={{ padding: 24 }}>
                      {riskCategory !== riskCategoryType.empty && <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography className={classes.riskTitle}>
                              {i18n.translations.riskCalculator.results.risk} {Math.round(calculatedRisk * 10) / 10}% - {i18n.translations.riskCalculator.results[riskCategory].title}
                            </Typography>
                          </Grid>
                          <Grid>
                            <img
                              src={riskCategoryIcon}
                              alt="Low CVD Risk icon"
                              className={clsx(classes.riskIconImg)}
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                      }
                      <Grid item xs={12}>
                        <Typography>
                          {i18n.translations.riskCalculator.results[riskCategory].description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {riskCategory !== riskCategoryType.empty && <Grid item xs={12} className={'result-card-bottom'}>
                    <Grid container spacing={2} style={{ padding: 24 }}>
                      <Grid item xs={12}>
                        <Typography
                          component="span"
                          className={classes.riskCardBottomText}
                          dangerouslySetInnerHTML={{
                            __html: i18n.translations.riskCalculator.results[riskCategory].cta,
                          }}>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        {riskCategory === riskCategoryType.low && <CustomButton
                          component={Link}
                          color="secondary"
                          variant="contained"
                          className={classes.resultCardButton}
                          style={{ minWidth: '50%' }}
                          to="/echipa"
                        >
                          {i18n.translations.riskCalculator.results[riskCategory].buttonText}
                        </CustomButton>}

                        {riskCategory !== riskCategoryType.low && <CustomButton
                          color="secondary"
                          variant="contained"
                          className={classes.resultCardButton}
                          style={{ minWidth: '50%' }}
                          onClick={() => setContactModalOpen(true)}
                        >
                          {i18n.translations.riskCalculator.results[riskCategory].buttonText}
                        </CustomButton>}

                      </Grid>
                    </Grid>
                  </Grid>
                  }
                </Grid>

              </Grid>
            </Grid>

          </form>
        </Grid>
      </Grid >
      <ContactPopup
        location={location}
        locale={locale}
        open={contactModalOpen}
        handleClose={() => setContactModalOpen(false)}
        additionalData={{
          age,
          gender,
          diabetes,
          smoking,
          totalCholesterol,
          totalCholesterolValueType: Object.keys(valueType).find(key => valueType[key] === totalCholesterolValueType),
          hdlCholesterol,
          hdlCholesterolValueType: Object.keys(valueType).find(key => valueType[key] === hdlCholesterolValueType),
          systolicBloodPressure,
          creatinine,
          creatinineValueType: Object.keys(valueType).find(key => valueType[key] === creatinineValueType),
          ageAtDiabetesDiagnosis,
          hba1c,
          hba1cValueType,
          egfr,
          riskCategory,
          calculatedRisk,
          scoreCalculatorType,
        }}
      />
    </div >
  )
}

export default RiskCalculator
