import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import "lazysizes"

import { graphql } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"
import GeneralContact from "../components/contacts/GeneralContact"
import { Location } from "@reach/router"
import RiskCalculator from "../components/form/RiskCalculator"
import MenuItems from "../components/departments/MenuItems"
import SectionTitle from "../components/SectionTitle"
import Breadcrumbs from "../components/Breadcrumbs"
import Accordion from "../components/Accordion"

const useStyles = makeStyles(() => ({
  video: {
    height: 260,
  },
  survey: {
    height: 2650,
    width: "100%",
  },
}))

const StaticPage = ({
  data: { staticPage },
  pageContext: { locale, slug, breadcrumbs },
  location,
}) => {
  const classes = useStyles()
  const schema = ""
  const ogp = []

  const meta = {
    title: staticPage.data.title.text,
    description: staticPage.data.meta_description || "",
    keywords: staticPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  return (
    <>
      <SEO schema={schema} ogp={ogp} meta={meta} locale={locale} />
      <Container maxWidth="lg">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <br />
        <Grid
          container
          spacing={4}
          justifyContent="center"
        >

          <Grid item xs={12} md={8}>
            <SectionTitle component="h1">
              {staticPage.data.title.text}
            </SectionTitle>
            <Grid container>

              {staticPage.data.body1 &&
                staticPage.data.body1.map(slice => (
                  <Grid item xs={12} key={shortid.generate()}>


                    {slice.slice_type === "simple" && (
                      <Typography
                        component="div"
                        dangerouslySetInnerHTML={{
                          __html: slice.primary.content.html,
                        }}
                      />
                    )}

                    {slice.slice_type === "survey" && (
                      <iframe
                        title="Survey"
                        src={slice.primary.survey_url.url}
                        frameBorder="0"
                        className={classes.survey}
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="auto"
                      />
                    )}

                    {slice.slice_type === "form" && (
                      <GeneralContact
                        location={location}
                        locale={locale}
                        formName={slice.primary.name}
                        disclaimerMessage={
                          slice.primary.disclaimer_message.html
                        }
                        sendToCRM={slice.primary.send_to_crm}
                        contactMessage={slice.primary.message_placeholder}
                      />
                    )}

                    {slice.slice_type === "risk_calculator" && (
                      <RiskCalculator
                        location={location}
                        locale={locale}
                      />
                    )}

                    {slice.slice_type === "accordion" && (
                      <div style={{ marginTop: 16 }}>
                        <Accordion items={slice.items} />
                      </div>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} key={shortid.generate()}>
            <div>
              <Location>
                {locationProps => (
                  <MenuItems locale={locale} slug={slug} {...locationProps} />
                )}
              </Location>
            </div>

          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default StaticPage

export const query = graphql`
  query staticPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    staticPage: prismicStaticPage(uid: { eq: $slug }, lang: { eq: $locale }) {
      data {
        title {
          text
        }
        content {
          html
        }
        body1 {
          ... on PrismicStaticPageDataBody1Simple {
            id
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicStaticPageDataBody1Survey {
            id
            slice_type
            primary {
              survey_url {
                url
              }
            }
          }

          ... on PrismicStaticPageDataBody1Form {
            id
            slice_type
            primary {
              name
              send_to_crm
              disclaimer_message {
                html
              }
              message_placeholder
            }
          }



          ... on PrismicStaticPageDataBody1RiskCalculator {
            id
            slice_type
          }



          ... on PrismicStaticPageDataBody1Accordion {
            id
            slice_type
            items {
              section_content {
                html
              }
              section_title {
                text
              }
            }
          }



          ... on PrismicStaticPageDataBody1Form {
            id
            slice_type
            primary {
              name
              send_to_crm
              disclaimer_message {
                html
              }
              message_placeholder
            }
          }
        }
      }
    }
  }
`

StaticPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    staticPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
